import { Inject, Injectable, inject } from '@angular/core';
import { from, Observable, of, Subscription } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';

import { Action, Booking } from '../models/booking';
import { RollbarService } from '../modules/rollbar';
import { CollectionReference, DocumentReference, Firestore, QueryConstraint, addDoc, collection, collectionData, doc, getDoc, query, updateDoc, where } from '@angular/fire/firestore';
import { httpsCallable, Functions } from '@angular/fire/functions';

import Rollbar from 'rollbar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UtilsService } from './utils.service';
import { Event } from '../models/event';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private firestore: Firestore = inject(Firestore);
  private functions: Functions = inject(Functions);

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    @Inject(RollbarService) private rollbar: Rollbar) { }


  //CRUD
  //Get a booking given the ID
  async getBooking(bookingId: string) {
    const docRef = doc(this.firestore, 'bookings', bookingId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
  }

  //Update a set of fields in a booking
  updateBooking(bookingId: string, fields: any) {
    const docRef = doc(this.firestore, 'bookings', bookingId);
    updateDoc(docRef, fields);
  }

  //Creates a new booking
  async addBooking(booking: Booking) {
    if (booking) {
      const colRef = collection(this.firestore, 'bookings');
      const docRef: DocumentReference = await addDoc(colRef, { ...booking });
      return this.getBooking(docRef.id);
    }
    return null;
  }

  //QUERIES
  //Get a booking by the reference booking number
  getBookingByBookingNumber(bookingNumber: string): Observable<Booking | null> {
    const colRef = collection(this.firestore, 'bookings');
    const q = query(colRef, where('bookingNumber', '==', bookingNumber.toUpperCase()));
    return collectionData(q, { idField: "id" }).pipe(
      map((bookings: Booking[]) => {
        return (bookings.length > 0 && bookings[0]) || null;
      })
    );
  }

  //Get bookings for an event, startDate, venueName, status and bookingNumber
  getBookings(eventId?: string | string[], startDate?: any, venueName?: string, status?: string | string[], bookingNumber?: String) {
    const startDateQuery = startDate ? (startDate.seconds ? new Date(startDate.seconds * 1000) : startDate) : null;
    const colRef: CollectionReference<Booking> = collection(this.firestore, 'bookings') as CollectionReference<Booking>;
    const queries: QueryConstraint[] = [];
    if (startDateQuery) {
      queries.push(where('startDate', '==', startDateQuery));
    }
    if (venueName) {
      queries.push(where('venueName', '==', venueName));
    }
    if (status) {
      if (Array.isArray(status) && status.length > 0) {
        queries.push(where('status', 'in', status));
      }
      if (!Array.isArray(status)) {
        queries.push(where('status', '==', status));
      }
    }
    if (bookingNumber) {
      queries.push(where('bookingNumber', '==', bookingNumber));
    }
    if (!Array.isArray(eventId)) {
      queries.push(where('eventId', '==', eventId));
    }
    console.log(queries);
    const q = query<Booking>(colRef, ...queries);
    return collectionData(q, { idField: "id" }).pipe(
      catchError((error, _) => {
        this.rollbar.error('Error getBookings', error);
        return of([]);
      })
    )
  }

  //Search for bookings with the given search term. Search inside booking ref number, customer phone and customer name
  searchInBookings(search_term: string, from_date_seconds: number, to_date_seconds: number, selectedEvent: Event): Observable<Object> {
    // Create a reference to your callable function
    const searchInBookings = httpsCallable(this.functions, 'searchInBookings');
    // Prepare the data to send
    const dataToSend = { 
      search_term
     };

    if (selectedEvent) {
      //Filter booking search by event
      dataToSend["event_id"] = selectedEvent.id;
      dataToSend["venue_name"] = selectedEvent.venueObj?.name;
      dataToSend["start_date_seconds"] = selectedEvent.startDate?.seconds;
    } else {
      if (from_date_seconds || to_date_seconds) {
        if (from_date_seconds) {
          //Filter booking search by events range
          dataToSend["from_date_seconds"] = from_date_seconds;
        }
        if (to_date_seconds) {
          //Filter booking search by events range
          dataToSend["to_date_seconds"] = to_date_seconds;
        }
      } else {
        return of({ data: [] });
      }
    }
    console.log(dataToSend);
    return from(searchInBookings(dataToSend)).pipe(
      timeout(30000),
      catchError((error) => {
        console.error('Error searchInBookings', error);
        return of({ error: true });
      })
    );
  }
  
  //ACTIONS
  //Add a new action to the list of actions in the booking, with 'executing' status
  executeAction(booking: Booking, action: Action) {
    if (booking && action) {
      if (!action.notifySMS || (action.notifySMS && booking.customerPhone)) {
        const actions = (booking.actions || []).concat(action);
        const docRef = doc(this.firestore, 'bookings', booking.id);
        updateDoc(docRef, { actions });
      }
    }
  }

  //OTHERS  
  //Resend the booking number to the given phone
  resendBookingNumber(phone_number: number, event_id: string): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-Origin', '*');
    const url = `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/sendBookingNumberReminder`;
    return this.http.get(url, { headers, params: { phone_number, event_id } }).pipe(
      timeout(30000),
      catchError((error) => {
        console.error('Error sendBookingNumber', error);
        return of({ success: 0, errorCode: 'error-sending' });
      })
    )
  }

  //Check if this phone number exists for an active booking
  existsPhoneForEvent(phone_number: number, event_id: string, start_date_seconds: number, venue_name: string): Observable<boolean> {
    if (phone_number && event_id && start_date_seconds && venue_name) {
      const headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-Origin', '*');
      const url = `https://us-central1-${environment.firebase.projectId}.cloudfunctions.net/existsPhoneForEvent`;
      return this.http.get(url, { headers, params: { phone_number, event_id, start_date_seconds, venue_name } }).pipe(
        timeout(30000),
        tap(e => console.log(e)),
        catchError((error) => {
          console.error('Error existsPhoneForEvent', error);
          return of(true);
        }),
        map((res: any) => res.exists || false)
      );
    }
    return of(false);
  }


}
